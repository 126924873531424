import { truncate, dateFormater } from '@/filters'

export default {
  name: 'EventItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    event: {
      type: Object,
      default: {}
    }
  },
  methods: {
    handleDeleteEvent () {
      this.$emit('deleteEvent', this.event.id)
    },
    handleEditEvent () {
      this.$emit('editEvent', this.event.id)
    }
  }
}
